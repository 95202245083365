body {
  margin: 0;
}
* {
  box-sizing: border-box;
}
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
a {
  color: inherit;
}
/* @font-face {
  font-family: "Sofia";
  font-style: normal;
  font-weight: normal;
  src: local("sofia-pro"), url(${fonts.SofiaRegularTTF}) format("truetype"),
    url(${fonts.SofiaRegularTTF}) format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Sofia";
  font-style: normal;
  font-weight: 600;
  src: local("sofia-pro"), url(${fonts.SofiaSemiBoldTTF}) format("truetype"),
    url(${fonts.SofiaSemiBoldWoff}) format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Sofia";
  font-style: normal;
  font-weight: 700;
  src: local("sofia-pro"), url(${fonts.SofiaBoldTTF}) format("truetype"),
    url(${fonts.SofiaBoldWoff}) format("woff");
  font-display: swap;
} */
img {
  color: transparent;
}
.fomo-notification .fomo-notification-image-wrapper img {
  margin-bottom: 0;
}
/**Disable modal scrolling */
.ReactModal__Body--open,
.ReactModal__Html--open {
  overflow: hidden;
}

button.needsclick.kl-private-reset-css-Xuajs1 {
  z-index: 90 !important;
}

/* #smile-ui-container {
  z-index: 90 !important;
}

#smile-ui-container .smile-launcher-frame-container {
  bottom: 20px !important;
}

#smile-ui-container.over-fixed-form .smile-launcher-frame-container {
  bottom: 120px !important;
}

#smile-ui-container:not(.over-fixed-form) .smile-panel-frame-container {
  height: calc(100% - 120px) !important;
  bottom: calc(100px) !important;
}
#smile-ui-container.over-fixed-form .smile-panel-frame-container {
  height: calc(100% - 220px) !important;
  bottom: calc(200px) !important;
} */

body {
  --sb-track-color: #ebebeb;
  --sb-thumb-color: #19b4eb;
  --sb-size: 6px;
}

*::-webkit-scrollbar {
  width: var(--sb-size);
  height: var(--sb-size);
}

*::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 3px;
}

*::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 3px;
}

@supports not selector(::-webkit-scrollbar) {
  * {
    scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
  }
}


div.needsclick.kl-private-reset-css-Xuajs1[role="dialog"]>div{
  overflow: hidden !important;
}

div.needsclick.kl-private-reset-css-Xuajs1[role="dialog"]>div img{
  overflow: hidden !important;
}

#chat-button{
  margin: 0;
  right: 6px !important;
  bottom: 13px !important;
}
::part(acsb-trigger){
  width: 50px !important;
  height: 50px !important;
}